.number-plate {
    display: inline-block;
    min-width: 150px;
    max-width: 100%;

    margin-bottom: 10px;
    padding: 4px 15px 6px;

    border-radius: 5px;
    border: 2px solid #FBDA06;
    box-shadow: inset 0px 0px 0px 1px #000, 0px 2px 4px 0px rgba(0,0,0,0.5);
    background: #FBDA06;

    color: black;
    text-align: center;
    padding: 4px 8px;
    line-height: 1.1;
    
    font-size: 150%;
    font-family: "CharlesWright-Bold", sans-serif;
    font-weight: normal;
    text-transform: uppercase;
}

.number-plate-input {
    width: 100%;
    max-width: 300px;
}

@media (max-width: 640px) {
    .number-plate-input {
        max-width: 200px;
    }
}