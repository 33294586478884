@media (max-width: 640px) {
    .responsive-table thead {
      display: none;
    }
    
    .responsive-table tbody, .responsive-table tr, .responsive-table td {
      display: block;
      width: 100%;
    }
    
    .responsive-table tr {
      margin-bottom: 1rem;
    }
    
    .responsive-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 1rem;
      font-weight: bold;
      text-align: left;
    }
  }
  