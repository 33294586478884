.icon-x-small {
    height: 1rem; /* equivalent to h-4 in Tailwind CSS */
    width: 1rem;  /* equivalent to w-4 in Tailwind CSS */
  }
  
  .icon-small {
    height: 1.5rem; /* equivalent to h-6 in Tailwind CSS */
    width: 1.5rem;  /* equivalent to w-6 in Tailwind CSS */
  }
  
  .icon-medium {
    height: 2rem; /* equivalent to h-8 in Tailwind CSS */
    width: 2rem;  /* equivalent to w-8 in Tailwind CSS */
  }
  
  .icon-large {
    height: 2.5rem; /* equivalent to h-10 in Tailwind CSS */
    width: 2.5rem;  /* equivalent to w-10 in Tailwind CSS */
  }
  
  .icon-x-large {
    height: 3rem; /* equivalent to h-12 in Tailwind CSS */
    width: 3rem;  /* equivalent to w-12 in Tailwind CSS */
  }