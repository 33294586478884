@import url("tailwindcss/base");
@import url("tailwindcss/components");
@import url("tailwindcss/utilities");

/* THe following font is used for the number plate component - https://www.1001freefonts.com/charles-wright.font */
@font-face {
    font-family: 'CharlesWright-Bold';
    src: url('./CharlesWright-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

html, body {
    min-height: 100%;
    background-color: #1F2937; /* bg-gray-800 */
}

.page-fill-background {
    background-color: white;
}